import { useEffect, useState } from "react";
import { fetchData, postData, putData } from "../../../helpers/endpoints";
import { useMobile } from "../../../hooks";
import "./Appointment.scss";
import FadeLoader from "react-spinners/FadeLoader";
import { CustomButton } from "../../../components/CustomButton";
import Swal from "sweetalert2";
import { CustomInput } from "../../../components/CustomInput";
import Modal from "react-modal";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import MyModal from "./modal/Mymodal";
import { useLocation } from "react-router-dom";

import { faL } from "@fortawesome/free-solid-svg-icons";

const Appointments = ({ patientId, name, mrn, email }) => {
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState({ url: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [newAppointmentIsOpen, setnewAppointmentIsOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const isMobile = useMobile();
  const [Step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [openTranslator, setOpenTranslator] = useState(false);
  const [langs, setLangs] = useState([]);
  const [lang, setLang] = useState("");
  const [item, setItem] = useState({});
  const currentpath = useLocation();

  const [options, setOptions] = useState([]);

  async function fetchAppointments() {
    setIsLoading(true);
    var date = new Date().toDateString();
    var res = await fetchData(
      "patient/" +
        patientId +
        "/appointment/?includes[]=translators&sortKey=startedAt&sortValue=-1&startedAtGte=" +
        date
    );
    // console.log(res)
    if (res.status === 200) {
      var tempelate = res.data.date;
      var i = 0;
      for (var appointment of res.data.date) {
        var title = await fetchtitle(appointment, i);
        tempelate[i] = { ...tempelate[i], title: title };
        if (appointment.startedAt) {
          let d = new Date(
            new Date(appointment.startedAt)
              .toLocaleString("en-US", {
                timeZone: "America/Los_Angeles",
              })
              .toString()
          );
          let temp = selectedDays;
          temp[i] = {
            year: d.getFullYear(),
            month: (d.getMonth() + 1) % 12,
            day: d.getDate(),
          };

          setSelectedDays(temp);
        }
        i++;
      }
      setAppointments(tempelate);
    }

    var res2 = await fetchData(
      "patient/" +
        patientId +
        "/appointment/event/index?type=public&perPage=15&page1"
    );

    if (res2.status === 200) {
      // console.log(res2);
      setServices(res2.data.data);
    } else {
      Swal.fire({
        title: "",
        text: res2.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    setIsLoading(false);
  }
  async function fetchtitle(appointment, i) {
    if (appointment.eventId) {
      var res = await fetchData(
        "patient/" + patientId + "/appointment/event/" + appointment.eventId
      );
      // console.log(res)
      if (res.status == 200) {
        if (res.data.data) return res.data.data.title;
        else return "temp";
      }
    }
  }
  const fetchLangs = async () => {
    setIsLoading(true);
    var res = await fetchData("user/config");
    if (res.status == 200) {
      // console.log(res.data.data.appointment.translator.languages);
      var filtered = res.data.data.appointment.translator.languages.filter(
        function (el) {
          return el != null;
        }
      );
      setLangs(filtered);
    }
    if (patientId == 0) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchLangs();
    if (patientId != 0) fetchAppointments();
  }, [patientId]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: "11",
    },
    content: {
      height: "55vh",
    },
  };
  return (
    <>
      <div
        dir="ltr"
        className="appointments d-flex flex-column gap-lg-5 gap-3 py-4 px-lg-3"
      >
        <div className="appointments d-flex flex-column gap-2">
          <h1>Current Appointments</h1>
        </div>

        {isLoading ? (
          <FadeLoader color={"#18324e"} className="mt-3" />
        ) : (
          <div className="appointments_row px-2 d-flex  ">
            <div className="col-lg-8 col-12">
              <>
                {appointments.map((a, i) => {
                  // console.log(a);
                  return Appointment(
                    a,
                    fetchtitle,
                    i,
                    setOpenTranslator,
                    openTranslator,
                    setItem,
                    patientId
                  );
                })}
              </>
              {/* <CustomButton
                uiType="primary"
                title={" Book a New Appointment"}
                onClick={() => {
                  setnewAppointmentIsOpen(true);
                }}
                additionalClassNames="mt-4"
              /> */}
            </div>
            {!isMobile && (
              <div
                className="col-lg-4 col-12 px-3 d-flex flex-column align-items-center"
                style={{ marginTop: "-60px" }}
              >
                <CustomButton
                  uiType="primary"
                  title={" Book a New Appointment"}
                  onClick={() => {
                    setnewAppointmentIsOpen(true);
                  }}
                  additionalClassNames="mb-4"
                />
                {
                  <Calendar
                    value={selectedDays}
                    shouldHighlightWeekends
                    colorPrimary="#F01791"
                    onChange={() => setSelectedDays(selectedDays)}
                    onRequestClose={() => {}}
                  />
                }
              </div>
            )}
          </div>
        )}
      </div>

      <MyModal
        isOpen={newAppointmentIsOpen}
        setisopen={setnewAppointmentIsOpen}
        width="45%"
      >
        {Step == 1 ? (
          <>
            <div style={{ width: "90%", textAlign: "center" }}>
              <div style={{ fontSize: "30px", fontWeight: "700" }}>
                Choose a Service
              </div>
              <hr />

              <CustomInput
                select
                // value={service}
                options={services.map((s) => {
                  return s.title;
                })}
                onChange={(e) => {
                  setService(
                    services.filter((s) => {
                      return s.title === e;
                    })[0]
                  );

                  setOptions(
                    services.filter((s) => {
                      return s.title === e;
                    })[0].locationTypes
                  );
                }}
                fullWidth
              />
              <button
                style={{
                  backgroundColor: "rgba(161, 129, 58, 1)",
                  borderRadius: "4px",
                  color: "white",
                  padding: "10px 40px",
                  border: "none",
                  marginTop: "40px",
                }}
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
              >
                Next
              </button>
            </div>
          </>
        ) : Step == 2 ? (
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              <path
                d="M22.8 11.3956H2.65371L10.0212 4.01421C10.2532 3.78219 10.2532 3.40603 10.0212 3.17401C9.78927 2.942 9.41322 2.942 9.18127 3.17401L0.781835 11.576C0.665861 11.6847 0.599976 11.8369 0.599976 11.996C0.599976 12.1552 0.665864 12.3074 0.781835 12.4161L9.18127 20.8181C9.29001 20.9341 9.44215 21 9.60124 21C9.76034 21 9.91248 20.9341 10.0212 20.8181C10.1372 20.7093 10.2031 20.5571 10.2031 20.398C10.2031 20.2388 10.1372 20.0867 10.0212 19.9779L2.65371 12.5965H22.8C23.1313 12.5965 23.4 12.3278 23.4 11.9964C23.4 11.665 23.1313 11.3963 22.8 11.3963V11.3956Z"
                fill="black"
              />
            </svg>

            <div
              style={{ width: "90%", textAlign: "center", marginTop: "20px" }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  whiteSpace: "nowrap",
                }}
              >
                Choose Your Preferred Type Of Appointment
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  margin: "16px 0px",
                }}
              >
                By selecting a location Type, you will be redirected to the
                physician’s calendar to book an appointment. You can come back
                here if the physician’s calendar did not match yours.
              </div>
              <hr />
              <div>
                {options.map((option, index) => (
                  <div
                    key={index}
                    style={{ width: "80%", margin: "auto", textAlign: "start" }}
                  >
                    <label>
                      <input
                        type="radio"
                        checked={selectedOption === option}
                        style={{
                          marginRight: "5px",
                          accentColor: "rgba(161, 129, 58, 1)",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOption(option);
                          }
                        }}
                      />
                      {option.type == "online_need_translation"
                        ? "online with interpreter"
                        : option.type == "offline_need_translation"
                        ? "inPerson with interpreter"
                        : option.type}
                    </label>
                    <hr />
                  </div>
                ))}
              </div>
              {/* <h2 style={{ fontSize: "22px", fontWeight: "600" }}>
                Do you need an Interpreter?
              </h2>

              <div className="w-100 mt-3 justify-content-center align-items-center d-flex flex-column gap-2">
                <CustomInput
                  additionalClassNames="w-50"
                  onChange={(e) => {
                    setLang(e);
                  }}
                  select
                  options={langs.map((l) => {
                    if (l) return l;
                  })}
                />
                {lang && (
                  <label>
                    The cost of your selected translator is $
                    {
                      langs.filter((l) => {
                        return l.key == lang;
                      })[0].price
                    }
                  </label>
                )}
              </div> */}

              <CustomButton
                style={{
                  backgroundColor: "rgba(161, 129, 58, 1)",
                  borderRadius: "4px",
                  color: "white",
                  padding: "10px 40px",
                  border: "none",
                  marginTop: "40px",
                }}
                onClick={async () => {
                  setIsLoading(true);
                  if (service.id && selectedOption.type) {
                    var res = await postData(
                      `patient/${patientId}/appointment`,
                      {
                        eventId: service.id,
                      }
                    );
                    if (res.status == 201) {
                      var link = document.createElement("a");
                      link.href =
                        res.data.data.schedulingLink +
                        "?utm_content=" +
                        JSON.stringify({
                          appointmentId: res.data.data.id,
                          patientId: patientId,
                          locationType: selectedOption.type,
                          hasTranslator: lang ? true : false,
                          translator_lang: lang,
                          redirectUrl:
                            "https://my-stg.allbrainsclinic.com" +
                            currentpath.pathname,
                        });
                      link.click();
                    } else {
                      Swal.fire({ title: res, icon: "error" });
                    }
                  } else {
                    Swal.fire({
                      title: "Please Choose your preferred type of Appointment",
                      icon: "error",
                    });
                  }
                  setIsLoading(false);
                  // setStep((prev) => prev + 1);
                }}
                title={"Next"}
                uiType="primary"
                loading={isLoading}
              />
            </div>
          </>
        ) : Step == 3 ? (
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              <path
                d="M22.8 11.3956H2.65371L10.0212 4.01421C10.2532 3.78219 10.2532 3.40603 10.0212 3.17401C9.78927 2.942 9.41322 2.942 9.18127 3.17401L0.781835 11.576C0.665861 11.6847 0.599976 11.8369 0.599976 11.996C0.599976 12.1552 0.665864 12.3074 0.781835 12.4161L9.18127 20.8181C9.29001 20.9341 9.44215 21 9.60124 21C9.76034 21 9.91248 20.9341 10.0212 20.8181C10.1372 20.7093 10.2031 20.5571 10.2031 20.398C10.2031 20.2388 10.1372 20.0867 10.0212 19.9779L2.65371 12.5965H22.8C23.1313 12.5965 23.4 12.3278 23.4 11.9964C23.4 11.665 23.1313 11.3963 22.8 11.3963V11.3956Z"
                fill="black"
              />
            </svg>
            <div style={{ width: "90%", textAlign: "center" }}>
              <div style={{ fontSize: "30px", fontWeight: "700" }}>
                Confirm Appointment
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  margin: "16px 0px",
                }}
              >
                By confirming, you will be redirected to Payment Page. Make sure
                to pay within 24 hours.
              </div>
              <hr />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    textAlign: "start",
                    padding: "10px",
                    // lineHeight: "20px",
                  }}
                >
                  <div style={{ fontWeight: "700", margin: "5px 5px" }}>
                    {" "}
                    Dr. Johanna Doe
                  </div>{" "}
                  <div
                    style={{
                      fontWeight: "400",
                      margin: "5px 5px",
                      color: "rgba(161, 129, 58, 1)",
                    }}
                  >
                    {" "}
                    MD FRCPC
                  </div>{" "}
                  <div
                    style={{
                      fontWeight: "400",
                      margin: "5px 5px",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    Child, Adolescent, and Adult Psychiatrist
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div>Service:</div>{" "}
                <div style={{ fontWeight: "700" }}>ADHD</div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div>Date:</div>{" "}
                <div style={{ fontWeight: "700" }}>August 3, 2024 </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div>Time:</div>{" "}
                <div style={{ fontWeight: "700" }}>
                  Starts at 03:15 PM - Finishes at 04:15 PM
                </div>
              </div>

              <button
                style={{
                  backgroundColor: "rgba(161, 129, 58, 1)",
                  borderRadius: "4px",
                  color: "white",
                  padding: "10px 40px",
                  border: "none",
                  marginTop: "40px",
                }}
                onClick={() => {}}
              >
                Save Appointment
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
      </MyModal>
      <MyModal
        isOpen={openTranslator}
        setisopen={setOpenTranslator}
        isLoading={isLoading}
      >
        <div className="d-flex flex-column gap-4 w-100 justify-content-center align-items-center">
          <h2 style={{ fontSize: "22px", fontWeight: "600" }}>
            Please choose the language?
          </h2>
          <div className="w-75">
            <CustomInput
              onChange={(e) => {
                setLang(e);
              }}
              select
              options={langs}
            />
          </div>

          {lang && (
            <label>
              The cost of your selected interpreter is $
              {
                langs.filter((l) => {
                  return l.key == lang;
                })[0].price
              }
            </label>
          )}
          <CustomButton
            onClick={async () => {
              setIsLoading(true);
              var res = await putData(
                `patient/${patientId}/appointment/${item.id}/translator`,
                { lang: lang }
              );
              if (res.status == 200) {
                var res2 = await putData(
                  `patient/${patientId}/appointment/${item.id}/translator/pay`,
                  { method: "online" }
                );
                if (res2.status == 200) {
                  window.location.href = res2.data.data.payment.checkoutUrl;
                }
              } else {
                Swal.fire({ text: res, icon: "error" });
              }
              setIsLoading(false);
            }}
            title={"Pay"}
            type="primary"
            loading={isLoading}
          />
        </div>
      </MyModal>
    </>
  );
};

const Appointment = (
  appointment,
  fetchtitle,
  i,
  setOpenTranslator,
  openTranslator,
  setItem,
  patientId
) => {
  // const [loading, setLoding] = useState(false);
  return (
    <div key={appointment.id} className="col-11 d-flex flex-row ">
      <div className="appointment col-9 d-flex flex-column px-3 py-4 gap-2">
        <h2>{appointment.title}</h2>
        {appointment.status != "pending" ? (
          <>
            {" "}
            <h3>
              Starts at{" "}
              {new Date(
                new Date(appointment.startedAt).toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
              )
                .toTimeString()
                .substring(0, 5)}
              {" , "}
              Finishes at{" "}
              {new Date(
                new Date(appointment.finishedAt).toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
              )
                .toTimeString()
                .substring(0, 5)}
            </h3>
            {new Date(appointment.startedAt) - new Date() < 90000 &&
              new Date(appointment.finishedAt) - new Date() > 0 &&
              appointment.url && (
                <a
                  className="enter d-flex flex-row mt-2 mb-2"
                  href={appointment.url}
                >
                  <h2>Enter meeting</h2>

                  <svg
                    width="7"
                    height="13"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.99475 6.3833C6.97009 6.12764 6.86674 5.88588 6.69912 5.69131L2.21682 0.466058C2.00595 0.203107 1.69727 0.0370092 1.36154 0.00542641C1.02577 -0.0259886 0.691595 0.0798874 0.435553 0.299099C0.179346 0.518137 0.0230171 0.831755 0.0023443 1.16797C-0.0183258 1.50434 0.0984027 1.83464 0.325772 2.08351L4.11558 6.5L0.325772 10.9165C0.0983983 11.1654 -0.0183285 11.4957 0.0023443 11.832C0.0230144 12.1682 0.179346 12.4818 0.435553 12.7009C0.691586 12.9201 1.02577 13.026 1.36154 12.9946C1.6973 12.963 2.00596 12.7969 2.21682 12.5339L6.69912 7.30869C6.91902 7.05303 7.02567 6.71907 6.99475 6.38343V6.3833Z"
                      fill="#18324E"
                    />
                  </svg>
                </a>
              )}
            {new Date(appointment.finishedAt) - new Date() > 172800 && (
              <div className="urls d-flex flex-row gap-3">
                <a href={appointment.rescheduleUrl}>Reschedule</a>
                <a style={{ color: "red" }} href={appointment.cancelUrl}>
                  Cancel Appointment
                </a>
              </div>
            )}
            {appointment.translators && appointment.translators.length == 0 ? (
              appointment.status != "pending" &&
              new Date(appointment.finishedAt) - new Date() > 172800 && (
                <div
                  style={{ color: "blue" }}
                  onClick={() => {
                    setItem(appointment);
                    setOpenTranslator(true);
                  }}
                >
                  add interpreter
                </div>
              )
            ) : (
              <div>
                {appointment.translators &&
                  appointment.translators.map((t) => {
                    return (
                      <div
                        style={{ fontWeight: "bold" }}
                        className="d-flex flex-column gap-2"
                      >
                        Interpreter:
                        <div className="d-flex flex-row gap-3 align-items-center">
                          {" "}
                          <h5 style={{ fontWeight: "normal" }}>
                            Languages: {t.lang}
                          </h5>
                          <h5 style={{ fontWeight: "normal" }}>
                            Status: {t.status}
                          </h5>
                          {t.status == "new" ? (
                            <CustomButton
                              additionalClassNames="col-2 py-1 px-1"
                              title={"Pay"}
                              // loading={loading}
                              uiType="primary2"
                              onClick={async () => {
                                // setLoding(true);
                                var res2 = await putData(
                                  `patient/${patientId}/appointment/${appointment.id}/translator/pay`,
                                  { method: "online" }
                                );
                                if (res2.status == 200) {
                                  window.location.href =
                                    res2.data.data.payment.checkoutUrl;
                                } else {
                                  Swal.fire({ text: res2, icon: "error" });
                                }
                                // setLoding(false);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        ) : (
          <div className="enter d-flex flex-row gap-3">
            <a style={{ color: "red" }} href={appointment.schedulingLink}>
              Schedule Appointment
            </a>
          </div>
        )}
      </div>
      <div className="date col-lg-3 col-5 px-2">
        {appointment.startedAt
          ? new Date(
              new Date(appointment.startedAt).toLocaleString("en-US", {
                timeZone: "America/Los_Angeles",
              })
            ).toDateString()
          : "Pending"}
      </div>
    </div>
  );
};

export default Appointments;
