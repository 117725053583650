import { useEffect, useState } from "react";
import {
  deleteData,
  fetchData,
  postData,
  putData,
  putFormData,
} from "../../../helpers/endpoints";
import MyModal from "./modal/Mymodal";
import { FileUploader } from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import { CustomInput } from "../../../components/CustomInput";
import { CustomButton } from "../../../components/CustomButton";
import { useAssets } from "../../../hooks";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { FadeLoader, PulseLoader } from "react-spinners";
import { useMobile } from "../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import "./PatientList.scss";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { EmailValidate, FaxValidate } from "../../../helpers/validate";
// import { is } from "immutable";
import Cookies from "js-cookie";
// import { faL } from "@fortawesome/free-solid-svg-icons";

const PatientList = ({ user }) => {
  const navigator = useNavigate();
  const { state } = useLocation();
  const isMobile = useMobile();
  const [patients, setPatients] = useState([]);
  const [isFirst, setisfirst] = useState(true);
  const [isDetails, setIsDetils] = useState(false);
  const [services, setServices] = useState("");
  const [patient, setPatient] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    sex: "",
    gender: "",
    relationship: "",
    careCard: "",
    emergencyContacts: [
      {
        relationship: "",
        country: "",
        phoneNumber: "",
        itemOpen: true,
      },
    ],
    teachers: [{ firstName: "", lastName: "", email: "", itemOpen: true }],
    serviceTypes: [],
  });
  const [options, setOption] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal2IsOpen, setIsOpen2] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [modal3IsOpen, setIsOpen3] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [img, setImg] = useState({});
  const [step, setStep] = useState(1);
  const [welcomeOpen, setWelcomeOpen] = useState(false);
  const [incomplateOpen, setInComplateOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [othersex, setothersex] = useState(false);
  const [showService, setshowService] = useState("");
  const [Checkitems, setCheckitems] = useState([]);
  const [othergender, setothergender] = useState(false);
  // const [Error, setError] = useState(true);
  const { getFile } = useAssets();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: 5,
    },
  };
  const uploadImage = async () => {
    setIsLoading(true);
    if (img.size) {
      if (img.size > 5 * 1024 * 1024) {
        Swal.fire({
          title: "",
          text: "Please upload a correct file.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        const options = {
          maxSizeMB: 1,
        };
        const compressedFile = await imageCompression(img, options);
        let formData = new FormData();
        formData.append("file", compressedFile);
        var res = await putFormData(
          "patient/" + patient.id + "/photo/upload",
          formData
        );
        if (res.status === 200) {
          setOpen(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          fetchUser();
        } else {
          Swal.fire({
            title: "",
            text: res,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    } else {
      Swal.fire({
        title: "",
        text: "Please upload a correct file.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    // if (isComplate(patient) && !patient.verifiedAt) {
    //   setVerifyOpen(true);
    // }
    setIsLoading(false);
  };

  async function fetchUser() {
    setIsLoading(true);
    var res = await fetchData("patient");
    if (res.status === 200) {
      var pati = res.data.data.filter((p) => {
        if (p.deletedAt) {
          return false;
        } else return true;
      });
      setPatients(pati);
      if (pati.length == 1 && state && state.from == "/sign-in") {
        navigator("/dashboard/" + pati[0].id + "/service");
      }
      if (pati.length == 1 && !isComplate(pati[0])) {
        setInComplateOpen(true);
      }
      if (pati.length == 0 && !Cookies.get("isFirst")) {
        setWelcomeOpen(true);
        Cookies.set("isFirst", true);
      }
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    var res2 = await fetchData("user/config");
    if (res2.status === 200) {
      var canf = res2.data.data.profile;
      setOption({
        ...options,
        Gender: Object.values(res2.data.data.patient.gender),
        Province: Object.keys(Object.values(canf.cities)[0]),
        CareerPosition: Object.values(canf.careers),
        Country: Object.keys(canf.cities),
        City: canf.cities,
        Sex: Object.values(res2.data.data.patient.sex),
        Pronouns: Object.values(canf.pronoun),
        Prefix: Object.values(canf.prefix),
        Relationship: Object.values(res2.data.data.patient.relationship),
        EmergnecyContactRelationship: Object.values(
          res2.data.data.patient.emergencyContact.relationship
        ),
      });
      console.log(options);
    } else {
      Swal.fire({
        title: "",
        text: res2,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    var res3 = await fetchData("service/type");
    if (res3.status === 200) {
      setServices(res3.data.data);
      // console.log(res3.data.data)
      if (res3.data.data) {
        setshowService(res3.data.data[0].title);
      }
    } else {
      Swal.fire({
        title: "",
        text: res3,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    setIsLoading(false);
  }
  useEffect(() => {
    fetchUser();
  }, []);
  const addPatient = async () => {
    setIsLoading(true);
    setIsOpen2(false);
    setOpen(true);
    await fetchUser();
    setIsLoading(false);
    if (isComplate(patient) && !patient.verifiedAt) {
      setVerifyOpen(true);
    }
  };
  const editPatient = async () => {
    setIsLoading(true);
    setIsOpen2(false);
    setIsDetils(false);
    if (isComplate(patient) && !patient.verifiedAt) {
      setVerifyOpen(true);
    }
    if (!patient.photo) {
      setPatient({ ...patient, id: patient.id });
      setOpen(true);
    } else
      setPatient({
        firstName: "",
        lastName: "",
        birthday: "",
        sex: "",
        gender: "",
        relationship: "",
        careCard: "",
        emergencyContacts: [
          {
            relationship: "",
            country: "",
            phoneNumber: "",
            itemOpen: true,
          },
        ],
        teachers: [{ firstName: "", lastName: "", email: "", itemOpen: true }],
        serviceTypes: [],
      });
    await fetchUser();

    setIsLoading(false);
  };
  const removePatient = async (id) => {
    setIsLoading(true);
    var res = await deleteData("patient/" + id);
    if (res.status === 200) {
      fetchUser();
      setIsOpen3(false);
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "Ok",
      });
      setIsOpen3(false);
    }
    setIsLoading(false);
  };

  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  const isComplate = (p) => {
    if (!p.firstName) {
      return false;
    }
    if (!p.lastName) {
      return false;
    }
    if (!p.birthday) {
      return false;
    }
    if (!p.sex) {
      return false;
    }
    if (!p.gender) {
      return false;
    }
    if (!p.careCard) {
      return false;
    }
    if (p.emergencyContacts.length === 0) {
      return false;
    }

    if (p.serviceTypes.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div dir="ltr" className="patientList">
        <h1 className="py-3"> Please choose the patient </h1>
        {!isLoading && patients.length == 0 && (
          <p
            className="mb-4 px-2"
            style={
              !isMobile
                ? { lineHeight: "24px", fontSize: "18px" }
                : { lineHeight: "16px", fontSize: "13px" }
            }
          >
            Welcome to AllBrains Dashboard. Here, you can purchase services,
            make appointments, and see your process. To begin, please add at
            least one patient's information to the list below. This is the first
            step to access all the features and manage care effectively.
          </p>
        )}
        {!isLoading ? (
          !isMobile ? (
            <table className="patientList_table">
              <thead>
                <tr className="patientList_table_header py-3 px-3">
                  <th
                    style={
                      patients.length === 0
                        ? { width: "100%" }
                        : { width: "18%", paddingLeft: "1.5rem" }
                    }
                  >
                    Name
                  </th>
                  {patients.length !== 0 && (
                    <>
                      <th style={{ width: "18%", textAlign: "center" }}>
                        Care Card (PHN)
                      </th>
                      <th style={{ width: "18%", textAlign: "center" }}>
                        Relationship
                      </th>
                      <th style={{ width: "10%", textAlign: "center" }}>
                        Clinical Code
                      </th>
                      <th style={{ width: "8%", textAlign: "center" }}>
                        Patient information
                      </th>
                      <th style={{ width: "4%", textAlign: "center" }} />
                      <th style={{ width: "4%", textAlign: "center" }} />
                    </>
                  )}
                </tr>
              </thead>
              {patients.map((p) => {
                return (
                  <tbody>
                    <tr
                      className={
                        p.verifiedAt
                          ? "patientList_table_row_active"
                          : "patientList_table_row"
                      }
                      title={
                        !isComplate(p) &&
                        "Please complete your information to access all of our services."
                      }
                    >
                      <td
                        title={
                          !isComplate(p) &&
                          "Please complete your information to access all of our services."
                        }
                        onClick={() => {
                          if (p.verifiedAt) {
                            navigator("/dashboard/" + p.id + "/service");
                            const date_default_patient = new Date();
                            localStorage.setItem(
                              "defaultPatient",
                              JSON.stringify(p.id)
                            );
                            localStorage.setItem(
                              "date_default_patient",
                              JSON.stringify(date_default_patient)
                            );
                          }

                          if (!isComplate(p)) {
                            let emerjency = p.emergencyContacts;
                            emerjency.map((e, i) => {
                              emerjency[i] = { ...e, itemOpen: false };
                            });
                            setPatient({
                              ...p,
                              birthday: new Date(p.birthday),
                              emergencyContacts: emerjency,
                            });
                            setIsDetils(true);
                            setIsOpen2(true);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        className="nameOfPatient"
                      >
                        {" "}
                        {/* <img src={getFile("avatar")} alt="avatar" /> */}
                        <h5>{p.firstName + " " + p.lastName}</h5>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {insertBlankAfterEveryThreeCharacters(p.careCard)}
                      </td>
                      <td style={{ textAlign: "center" }}>{p.relationship}</td>
                      <td
                        style={
                          p.verifiedAt
                            ? { textAlign: "center" }
                            : { textAlign: "center", color: "red" }
                        }
                      >
                        {p.verifiedAt ? p.id : "Not yet verified"}
                      </td>
                      <td
                        onClick={() => {
                          if (!isComplate(p)) {
                            let emerjency = p.emergencyContacts;
                            emerjency.map((e, i) => {
                              emerjency[i] = { ...e, itemOpen: false };
                            });
                            setPatient({
                              ...p,
                              birthday: new Date(p.birthday),
                              emergencyContacts: emerjency,
                            });
                            setIsDetils(true);
                            setIsOpen2(true);
                          }
                        }}
                        style={
                          isComplate(p)
                            ? { textAlign: "center" }
                            : {
                                textAlign: "center",
                                color: "red",
                                cursor: "pointer",
                              }
                        }
                        title={
                          !isComplate(p) &&
                          "Please complete your information to access all of our services."
                        }
                      >
                        {isComplate(p) ? "" : "InComplete"}
                      </td>
                      <td
                        style={{
                          color: "#18324E",
                          paddingLeft: 0,
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          let emerjency = p.emergencyContacts;
                          emerjency.map((e, i) => {
                            emerjency[i] = { ...e, itemOpen: false };
                          });
                          setPatient({
                            ...p,
                            birthday: new Date(p.birthday),
                            emergencyContacts: emerjency,
                          });
                          setIsDetils(true);
                          setIsOpen2(true);
                        }}
                      >
                        Details
                      </td>
                      <td
                        style={{
                          color: "#BC2323",
                          paddingLeft: 0,
                          paddingRight: "1.5rem",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPatient({ ...p, birthday: new Date(p.birthday) });
                          setIsOpen3(true);
                        }}
                      >
                        Remove
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              {patients.length === 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className="d-flex gap-2 flex-row justify-content-center align-items-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C5.87829 0 3.84335 0.842847 2.34316 2.34316C0.842963 3.84346 0 5.87822 0 8C0 10.1218 0.842847 12.1567 2.34316 13.6568C3.84346 15.157 5.87822 16 8 16C10.1218 16 12.1567 15.1572 13.6568 13.6568C15.157 12.1565 16 10.1218 16 8C15.9982 5.87888 15.1547 3.8449 13.6549 2.3451C12.1551 0.845258 10.1212 0.00182855 8 0ZM8.36078 12.2754C8.26474 12.3707 8.13525 12.4244 8 12.4253C7.86459 12.4251 7.7348 12.3713 7.63921 12.2754C7.54393 12.1794 7.49028 12.0499 7.48937 11.9146C7.48952 11.7792 7.54332 11.6495 7.63921 11.5539C7.8421 11.3636 8.1579 11.3636 8.36079 11.5539C8.45669 11.6495 8.51049 11.7792 8.51064 11.9146C8.50973 12.0499 8.45608 12.1794 8.36078 12.2754ZM8.51063 9.87213C8.51063 10.1542 8.28206 10.3828 8 10.3828C7.71794 10.3828 7.48937 10.1542 7.48937 9.87213V4.08495C7.48937 3.80289 7.71794 3.57432 8 3.57432C8.28206 3.57432 8.51063 3.80289 8.51063 4.08495V9.87213Z"
                        fill="#B13737"
                      />
                    </svg>

                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "20",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      There is no Patient Info set. Please add at least one
                      patient’s information to continue.
                    </h1>
                  </td>
                </tr>
              )}
              <tr className="patientList_table_footer  py-2">
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                >
                  {" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px", marginBottom: "3px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
                      fill="#A1813A"
                    />
                  </svg>
                  {"   "}
                  Add Patient
                </td>
                {patients.length !== 0 && (
                  <>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </>
                )}
              </tr>
            </table>
          ) : (
            <div
              style={{
                maxWidth: "85vw",
                maxHeight: "70vh",
                overflowX: "scroll",
                overflowY: "scroll",
              }}
            >
              <table className="patientList_table">
                <thead>
                  <tr className="patientList_table_header py-3 px-3">
                    <th
                      style={
                        patients.length === 0
                          ? { width: "100%" }
                          : {
                              width: "18%",
                              minWidth: "80px",
                              paddingLeft: "1.5rem",
                            }
                      }
                    >
                      Name
                    </th>
                    {patients.length !== 0 && (
                      <>
                        <th
                          style={{
                            width: "18%",
                            minWidth: "80px",
                            textAlign: "center",
                          }}
                        >
                          Care Card (PHN)
                        </th>
                        <th style={{ width: "18%", textAlign: "center" }}>
                          Relationship
                        </th>
                        <th style={{ width: "10%", textAlign: "center" }}>
                          Clinical Code
                        </th>
                        <th style={{ width: "8%", textAlign: "center" }} />
                        <th style={{ width: "4%", textAlign: "center" }} />
                        <th style={{ width: "4%", textAlign: "center" }} />
                      </>
                    )}
                  </tr>
                </thead>
                {patients.map((p) => {
                  return (
                    <tbody>
                      <tr
                        className={
                          p.verifiedAt
                            ? "patientList_table_row_active"
                            : "patientList_table_row"
                        }
                        title={
                          !isComplate(p) &&
                          "Please complete your information to access all of our services."
                        }
                      >
                        <td
                          title={
                            !isComplate(p) &&
                            "Please complete your information to access all of our services."
                          }
                          onClick={() => {
                            if (p.verifiedAt) {
                              navigator("/dashboard/" + p.id + "/service");
                              const date_default_patient = new Date();
                              localStorage.setItem(
                                "defaultPatient",
                                JSON.stringify(p.id)
                              );
                              localStorage.setItem(
                                "date_default_patient",
                                JSON.stringify(date_default_patient)
                              );
                            }

                            if (!isComplate(p)) {
                              let emerjency = p.emergencyContacts;
                              emerjency.map((e, i) => {
                                emerjency[i] = { ...e, itemOpen: false };
                              });
                              setPatient({
                                ...p,
                                birthday: new Date(p.birthday),
                                emergencyContacts: emerjency,
                              });
                              setIsDetils(true);
                              setIsOpen2(true);
                            }
                          }}
                          style={{ cursor: "pointer", minWidth: "80px" }}
                          className="nameOfPatient"
                        >
                          {" "}
                          {/* <img src={getFile("avatar")} alt="avatar" /> */}
                          <h5>{p.firstName + " " + p.lastName}</h5>
                        </td>
                        <td style={{ textAlign: "center", minWidth: "80px" }}>
                          {insertBlankAfterEveryThreeCharacters(p.careCard)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {p.relationship}
                        </td>
                        <td
                          style={
                            p.verifiedAt
                              ? { textAlign: "center" }
                              : { textAlign: "center", color: "red" }
                          }
                        >
                          {p.verifiedAt ? p.id : "Not yet verified"}
                        </td>
                        <td
                          onClick={() => {
                            if (!isComplate(p)) {
                              let emerjency = p.emergencyContacts;
                              emerjency.map((e, i) => {
                                emerjency[i] = { ...e, itemOpen: false };
                              });
                              setPatient({
                                ...p,
                                birthday: new Date(p.birthday),
                                emergencyContacts: emerjency,
                              });
                              setIsDetils(true);
                              setIsOpen2(true);
                            }
                          }}
                          style={
                            isComplate(p)
                              ? { textAlign: "center" }
                              : {
                                  textAlign: "center",
                                  color: "red",
                                  cursor: "pointer",
                                }
                          }
                          title={
                            !isComplate(p) &&
                            "Please complete your information to access all of our services."
                          }
                        >
                          {isComplate(p) ? "Complete" : "InComplete"}
                        </td>
                        <td
                          style={{
                            color: "#18324E",
                            // paddingLeft: 0,
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            let emerjency = p.emergencyContacts;
                            emerjency.map((e, i) => {
                              emerjency[i] = { ...e, itemOpen: false };
                            });
                            setPatient({
                              ...p,
                              birthday: new Date(p.birthday),
                              emergencyContacts: emerjency,
                            });
                            setIsDetils(true);
                            setIsOpen2(true);
                          }}
                        >
                          Details
                        </td>
                        <td
                          style={{
                            color: "#BC2323",
                            // paddingLeft: 0,
                            paddingRight: "1.5rem",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPatient({
                              ...p,
                              birthday: new Date(p.birthday),
                            });
                            setIsOpen3(true);
                          }}
                        >
                          Remove
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
                {patients.length === 0 && (
                  <tr>
                    <td
                      colSpan={7}
                      className="d-flex gap-2 flex-row justify-content-center align-items-center"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 0C5.87829 0 3.84335 0.842847 2.34316 2.34316C0.842963 3.84346 0 5.87822 0 8C0 10.1218 0.842847 12.1567 2.34316 13.6568C3.84346 15.157 5.87822 16 8 16C10.1218 16 12.1567 15.1572 13.6568 13.6568C15.157 12.1565 16 10.1218 16 8C15.9982 5.87888 15.1547 3.8449 13.6549 2.3451C12.1551 0.845258 10.1212 0.00182855 8 0ZM8.36078 12.2754C8.26474 12.3707 8.13525 12.4244 8 12.4253C7.86459 12.4251 7.7348 12.3713 7.63921 12.2754C7.54393 12.1794 7.49028 12.0499 7.48937 11.9146C7.48952 11.7792 7.54332 11.6495 7.63921 11.5539C7.8421 11.3636 8.1579 11.3636 8.36079 11.5539C8.45669 11.6495 8.51049 11.7792 8.51064 11.9146C8.50973 12.0499 8.45608 12.1794 8.36078 12.2754ZM8.51063 9.87213C8.51063 10.1542 8.28206 10.3828 8 10.3828C7.71794 10.3828 7.48937 10.1542 7.48937 9.87213V4.08495C7.48937 3.80289 7.71794 3.57432 8 3.57432C8.28206 3.57432 8.51063 3.80289 8.51063 4.08495V9.87213Z"
                          fill="#B13737"
                        />
                      </svg>

                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: "20",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                      >
                        There is no Patient Info set. Please add at least one
                        patient’s information to continue.
                      </h1>
                    </td>
                  </tr>
                )}
                <tr className="patientList_table_footer  py-2">
                  <td
                    style={{ cursor: "pointer", minWidth: "100px" }}
                    onClick={() => setIsOpen(true)}
                  >
                    {" "}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "5px", marginBottom: "3px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
                        fill="#A1813A"
                      />
                    </svg>
                    {"   "}
                    Add Patient
                  </td>
                  {patients.length !== 0 && (
                    <>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </>
                  )}
                </tr>
              </table>
            </div>
            // <table className="patientList_table">
            //   <thead>
            //     <tr className="patientList_table_header py-3 px-3">
            //       <th style={{ width: "60%" }}>Name</th>
            //       <th style={{ width: "20%" }} />
            //       <th style={{ width: "20%" }} />
            //     </tr>
            //   </thead>
            //   {patients.map((p) => {
            //     return (
            //       <tbody>
            //         <tr className="patientList_table_row">
            //           <td>
            //             {" "}
            //             {/* <img src={getFile("avatar")} alt="avatar" /> */}
            //             <h5
            //             // style={{ marginLeft: "2.5rem" }}
            //             >
            //               table
            //               {p.firstName + " " + p.lastName}
            //             </h5>
            //           </td>
            //           <td
            //             style={{
            //               color: "#18324E",
            //               paddingLeft: 0,
            //               cursor: "pointer",
            //             }}
            //             onClick={() => {
            //               let emerjency = p.emergencyContacts;
            //               emerjency.map((e, i) => {
            //                 emerjency[i] = { ...e, itemOpen: false };
            //               });
            //               setPatient({
            //                 ...p,
            //                 birthday: new Date(p.birthday),
            //                 emergencyContacts: emerjency,
            //               });
            //               setIsDetils(true);
            //               setIsOpen2(true);
            //             }}
            //           >
            //             Details
            //           </td>
            //           <td
            //             style={{
            //               color: "#BC2323",
            //               paddingLeft: 0,
            //               paddingRight: "1.5rem",
            //             }}
            //             onClick={() => {
            //               setPatient({ ...p, birthday: new Date(p.birthday) });
            //               setIsOpen3(true);
            //             }}
            //           >
            //             Remove
            //           </td>
            //         </tr>
            //       </tbody>
            //     );
            //   })}
            //   {patients.length === 0 && (
            //     <tr>
            //       <td
            //         colSpan={6}
            //         className="d-flex gap-2 flex-row justify-content-center align-items-center"
            //       >
            //         <svg
            //           width="80"
            //           height="80"
            //           viewBox="0 0 80 80"
            //           fill="none"
            //           xmlns="http://www.w3.org/2000/svg"
            //         >
            //           <path
            //             d="M40 0C29.3915 0 19.2167 4.21423 11.7158 11.7158C4.21482 19.2173 0 29.3911 0 40C0 50.6089 4.21423 60.7833 11.7158 68.2842C19.2173 75.7852 29.3911 80 40 80C50.6089 80 60.7833 75.7858 68.2842 68.2842C75.7852 60.7827 80 50.6089 80 40C79.9909 29.3944 75.7735 19.2245 68.2745 11.7255C60.7753 4.22629 50.606 0.00914277 40 0ZM41.8039 61.3771C41.3237 61.8536 40.6763 62.1218 40 62.1264C39.3229 62.1256 38.674 61.8566 38.1961 61.3771C37.7196 60.8969 37.4514 60.2495 37.4468 59.5732C37.4476 58.8962 37.7166 58.2472 38.1961 57.7693C39.2105 56.8179 40.7895 56.8179 41.804 57.7693C42.2834 58.2472 42.5524 58.8962 42.5532 59.5732C42.5486 60.2495 42.2804 60.8969 41.8039 61.3771ZM42.5532 49.3606C42.5532 50.771 41.4103 51.9138 40 51.9138C38.5897 51.9138 37.4468 50.771 37.4468 49.3606V20.4247C37.4468 19.0144 38.5897 17.8716 40 17.8716C41.4103 17.8716 42.5532 19.0144 42.5532 20.4247V49.3606Z"
            //             fill="#B13737"
            //           />
            //         </svg>

            //         <h1
            //           style={{
            //             textAlign: "center",
            //             fontSize: "20",
            //             fontWeight: "bold",
            //           }}
            //         >
            //           There is no Patient Info set. Please add at least one
            //           patient’s information to continue.
            //         </h1>
            //       </td>
            //     </tr>
            //   )}
            //   <tr className="patientList_table_footer  py-2">
            //     <td
            //       style={{ cursor: "pointer" }}
            //       onClick={() => setIsOpen(true)}
            //     >
            //       + Add Patient
            //     </td>
            //     <td />
            //     <td />
            //   </tr>
            // </table>
          )
        ) : (
          <FadeLoader color={"#18324e"} />
        )}
      </div>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={async () => {
          setIsOpen(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          setIsDetils(false);
          await fetchUser();
          setStep(1);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal1"
      >

      </Modal> */}
      <MyModal
        isOpen={modalIsOpen}
        setisopen={setIsOpen}
        isLoading={isLoading}
        width={isMobile ? "85%" : "60%"}
        CloseFunction={async () => {
          setisEdit(false);
          setCheckitems([]);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          setIsDetils(false);
          await fetchUser();
          setStep(1);
        }}
      >
        <div style={{ maxWidth: "700px", width: "100%" }}>
          <y className="d-flex w-100">
            {step != 1 && (
              <svg
                style={{ justifySelf: "left", marginTop: "10px" }}
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={async () => {
                  if (step == 1) {
                    setPatient({
                      firstName: "",
                      lastName: "",
                      birthday: "",
                      sex: "",
                      gender: "",
                      relationship: "",
                      careCard: "",
                      emergencyContacts: [
                        {
                          relationship: "",
                          country: "",
                          phoneNumber: "",
                          itemOpen: true,
                        },
                      ],
                      teachers: [
                        {
                          firstName: "",
                          lastName: "",
                          email: "",
                          itemOpen: true,
                        },
                      ],
                      serviceTypes: [],
                    });
                    await fetchUser();
                    setIsOpen(false);
                  } else {
                    if (step == 3) setStep(1);
                    else setStep(step - 1);
                  }
                }}
              >
                <path
                  d="M22.7996 8.39557H2.65334L10.0208 1.01421C10.2528 0.78219 10.2528 0.40603 10.0208 0.174013C9.7889 -0.0580043 9.41285 -0.0580043 9.18091 0.174013L0.781469 8.57595C0.665495 8.68473 0.599609 8.83691 0.599609 8.99605C0.599609 9.15519 0.665498 9.30737 0.781469 9.41615L9.18091 17.8181C9.28965 17.9341 9.44178 18 9.60088 18C9.75997 18 9.91211 17.9341 10.0208 17.8181C10.1368 17.7093 10.2027 17.5571 10.2027 17.398C10.2027 17.2388 10.1368 17.0867 10.0208 16.9779L2.65334 9.59653H22.7996C23.131 9.59653 23.3996 9.32781 23.3996 8.99639C23.3996 8.66498 23.131 8.39625 22.7996 8.39625V8.39557Z"
                  fill="black"
                />
              </svg>
            )}
          </y>
          {step === 1 && (
            <div className="info d-flex flex-column justify-content-center align-items-center gap-5 pb-5 ">
              <h2>Who is this patient profile for?</h2>
              <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                <CustomButton
                  title={"It is for my dependent"}
                  additionalClassNames="col-7 py-2"
                  uiType={"border"}
                  onClick={() => {
                    setStep(2);
                  }}
                />
                <CustomButton
                  title={"It is for myself"}
                  uiType="primary2"
                  additionalClassNames="col-7 py-2 border-2"
                  onClick={() => {
                    setStep(3);
                    setPatient(user.profile);
                    setPatient({
                      ...patient,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      relationship: "My-self",
                    });
                  }}
                />
              </div>
            </div>
          )}
          {(step === 2 || step === 3) &&
            PersonalInfo(
              patient,
              setPatient,
              options,
              setStep,
              step,
              setIsLoading,
              isLoading,
              isMobile,
              othergender,
              othersex,
              setothergender,
              setothersex,
              setisEdit,
              isEdit,
              editPatient,
              setIsOpen,
              fetchUser,
              setIsOpen2
            )}
          {step === 4 &&
            EmergnecyContact(
              // Error,
              // setError,
              patient,
              options,
              setPatient,
              setStep,
              getFile,
              isMobile,
              isLoading,
              setIsLoading,
              setisEdit,
              isEdit,
              editPatient,
              setIsOpen,
              fetchUser,
              setIsOpen2,
              isFirst,
              setisfirst
            )}
          {step === 5 &&
            Referral(
              patient,
              setPatient,
              setStep,
              isMobile,
              isLoading,
              setIsLoading,
              setisEdit,
              isEdit,
              editPatient,
              setIsOpen,
              fetchUser
            )}
          {step === 6 &&
            EmailAddress(
              patient,
              setPatient,
              setStep,
              getFile,
              isMobile,
              isLoading,
              setIsLoading,
              setisEdit,
              isEdit,
              editPatient,
              setIsOpen,
              fetchUser,
              setIsOpen2,
              isFirst,
              setisfirst
            )}
          {step === 7 &&
            Services(
              services,
              setStep,
              setIsOpen,
              setIsOpen2,
              patient,
              setPatient,
              showService,
              setshowService,
              Checkitems,
              setCheckitems,
              setisEdit,
              isEdit,
              editPatient,
              // setIsOpen,
              fetchUser,
              isLoading,
              setIsLoading
            )}
        </div>
      </MyModal>
      {/* <Modal
        isOpen={modal2IsOpen}
        onRequestClose={async () => {
          setIsOpen2(false);
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
          await fetchUser();
          setIsDetils(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal2"
      ></Modal> */}

      <MyModal
        isOpen={modal2IsOpen}
        setisopen={setIsOpen2}
        isLoading={isLoading}
        width={"75%"}
        CloseFunction={async () => {
          setIsOpen2(false);

          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });

          await fetchUser();
          setIsDetils(false);
        }}
      >
        {Detile(
          patient,
          isDetails,
          addPatient,
          editPatient,
          isLoading,
          setIsOpen2,
          setIsOpen,
          setStep,
          services,
          setPatient,
          fetchUser,
          isEdit,
          setisEdit
        )}
      </MyModal>

      <MyModal
        type="question"
        isOpen={modal3IsOpen}
        setisopen={setIsOpen3}
        Title={`Are you sure to delete ${patient.firstName} ${patient.lastName}?`}
        isLoading={isLoading}
        OkFunction={() => removePatient(patient.id)}
      />
      <MyModal
        isOpen={isOpen}
        isLoading={isLoading}
        setisopen={setOpen}
        CloseFunction={() => {
          setPatient({
            firstName: "",
            lastName: "",
            birthday: "",
            sex: "",
            gender: "",
            relationship: "",
            careCard: "",
            emergencyContacts: [
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
            teachers: [
              { firstName: "", lastName: "", email: "", itemOpen: true },
            ],
            serviceTypes: [],
          });
        }}
      >
        {" "}
        <div className="d-flex flex-column justify-content-center gap-5 px-3">
          {/* <svg
            style={{
              justifySelf: "right",
              marginLeft: "100%",
              marginBottom: "-50px",
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setOpen(false);
            }}
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg> */}
          <div>
            <h2
              className="my-4"
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {" "}
              Please Upload Patient Photo.
            </h2>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              You can upload up to 5 MB.
            </p>
          </div>
          <FileUploader
            required
            handleChange={(file) => setImg(file)}
            name="file"
            types={["JPG", "PNG"]}
            maxSize={5}
          />
          <div
            className="d-flex flex-row col-12"
            style={{ justifyContent: "space-around" }}
          >
            <CustomButton
              uiType="primary2"
              title={"Skip"}
              additionalClassNames="col-4"
              onClick={() => {
                // if (isComplate(patient) && !patient.verifiedAt) {
                //   setVerifyOpen(true);
                // }
                setPatient({
                  firstName: "",
                  lastName: "",
                  birthday: "",
                  sex: "",
                  gender: "",
                  relationship: "",
                  careCard: "",
                  emergencyContacts: [
                    {
                      relationship: "",
                      country: "",
                      phoneNumber: "",
                      itemOpen: true,
                    },
                  ],
                  teachers: [
                    { firstName: "", lastName: "", email: "", itemOpen: true },
                  ],
                  serviceTypes: [],
                });
                fetchUser();
                setOpen(false);
              }}
              loading={isLoading}
            />
            <CustomButton
              uiType="primary2"
              title={"Done"}
              additionalClassNames="col-4"
              onClick={uploadImage}
              loading={isLoading}
            />
          </div>
        </div>
      </MyModal>

      <MyModal
        isOpen={welcomeOpen}
        setisopen={setWelcomeOpen}
        isLoading={isLoading}
      >
        {" "}
        <div className="d-flex flex-column justify-content-center gap-5 px-3">
          {/* <svg
            style={{
              justifySelf: "right",
              marginLeft: "94%",
              marginBottom: "-60px",
              marginTop: "20px",
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setWelcomeOpen(false);
            }}
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg> */}
          <div>
            <h2
              className="my-1"
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "700",
              }}
            >
              {" "}
              Welcome
            </h2>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "24px",
                marginTop: "20px",
              }}
            >
              To begin, please add at least one patient's information. This is
              the first step to access all the features and manage care
              effectively.
            </p>
          </div>

          <div
            className="d-flex flex-row col-12"
            style={{ justifyContent: "space-around" }}
          >
            <CustomButton
              uiType="primary"
              title={"Start"}
              additionalClassNames="col-4"
              onClick={() => {
                setIsOpen(true);
                setWelcomeOpen(false);
              }}
            />
          </div>
        </div>
      </MyModal>

      <MyModal
        isOpen={incomplateOpen}
        setisopen={setInComplateOpen}
        isLoading={isLoading}
      >
        {" "}
        <div className="d-flex flex-column justify-content-center gap-4 px-4">
          {/* <svg
            style={{
              justifySelf: "right",
              marginLeft: "94%",
              marginBottom: "-60px",
              marginTop: "20px",
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setInComplateOpen(false);
            }}
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg> */}
          <div className="align-items-center justify-content-center d-flex flex-column gap-2">
            <svg
              // style={{ marginLeft: "40%" }}
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40 0C29.3915 0 19.2167 4.21423 11.7158 11.7158C4.21482 19.2173 0 29.3911 0 40C0 50.6089 4.21423 60.7833 11.7158 68.2842C19.2173 75.7852 29.3911 80 40 80C50.6089 80 60.7833 75.7858 68.2842 68.2842C75.7852 60.7827 80 50.6089 80 40C79.9909 29.3944 75.7735 19.2245 68.2745 11.7255C60.7753 4.22629 50.606 0.00914277 40 0ZM41.8039 61.3771C41.3237 61.8536 40.6763 62.1218 40 62.1264C39.3229 62.1256 38.674 61.8566 38.1961 61.3771C37.7196 60.8969 37.4514 60.2495 37.4468 59.5732C37.4476 58.8962 37.7166 58.2472 38.1961 57.7693C39.2105 56.8179 40.7895 56.8179 41.804 57.7693C42.2834 58.2472 42.5524 58.8962 42.5532 59.5732C42.5486 60.2495 42.2804 60.8969 41.8039 61.3771ZM42.5532 49.3606C42.5532 50.771 41.4103 51.9138 40 51.9138C38.5897 51.9138 37.4468 50.771 37.4468 49.3606V20.4247C37.4468 19.0144 38.5897 17.8716 40 17.8716C41.4103 17.8716 42.5532 19.0144 42.5532 20.4247V49.3606Z"
                fill="#B13737"
              />
            </svg>

            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "24px",
                marginTop: "20px",
              }}
            >
              Please complete your information to access all of our services.
            </p>
          </div>

          <div
            className="d-flex flex-row col-12"
            style={{ justifyContent: "space-around" }}
          >
            <CustomButton
              uiType="primary"
              title={"Ok"}
              additionalClassNames="col-4"
              onClick={() => {
                setInComplateOpen(false);
              }}
            />
          </div>
        </div>
      </MyModal>

      <MyModal
        isOpen={verifyOpen}
        setisopen={setVerifyOpen}
        isLoading={isLoading}
      >
        {" "}
        <div className="d-flex flex-column justify-content-center gap-5 px-4">
          {/* <svg
            style={{
              justifySelf: "right",
              marginLeft: "94%",
              marginBottom: "-50px",
              marginTop: "20px",
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setVerifyOpen(false);
            }}
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg> */}
          <div>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "24px",
                marginTop: "20px",
                fontWeight: "550",
              }}
            >
              Your information will be verified within the next 2-3 working
              days. After that you will have access to the services.
            </p>
          </div>

          <div
            className="d-flex flex-row col-12"
            style={{ justifyContent: "space-around" }}
          >
            <CustomButton
              uiType="primary"
              title={"Ok"}
              additionalClassNames="col-4"
              onClick={() => {
                setVerifyOpen(false);
              }}
            />
          </div>
        </div>
      </MyModal>
    </>
  );
};

const PersonalInfo = (
  patient,
  setPatient,
  options,
  setStep,
  step,
  setIsLoading,
  isLoading,
  isMobile,
  othergender,
  othersex,
  setothergender,
  setothersex,
  setisEdit,
  isEdit = false,
  editPatient,
  setIsOpen,
  fetchUser,
  setIsOpen2
) => {
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  return (
    <>
      <div className=" info d-flex flex-column justify-content-center align-items-center gap-4 pb-5 px-3">
        <h2>Enter Patient’s Personal Information</h2>
        <CustomInput
          label={"First Name"}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.firstName}
          onChange={(e) => {
            setPatient({ ...patient, firstName: e });
          }}
        />
        <CustomInput
          label={"Last Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.lastName}
          onChange={(e) => {
            setPatient({ ...patient, lastName: e });
          }}
        />

        <CustomInput
          label={"Sex"}
          select
          options={options.Sex}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.sex}
          onChange={(e) => {
            if (e == "Other") {
              setPatient({ ...patient, sex: e });
              setothersex(true);
            } else {
              setothersex(false);
              setPatient({ ...patient, sex: e });
            }
          }}
        />
        {othersex || patient.sex == "Other" ? (
          <CustomInput
            label={"Other sex extra info"}
            required
            fullWidth
            isRow={!isMobile}
            value={patient.sexExtraInfo}
            onChange={(e) => {
              setPatient({ ...patient, sexExtraInfo: e });
            }}
          />
        ) : (
          <></>
        )}

        <CustomInput
          label={"Gender Identity"}
          select
          options={options.Gender}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.gender}
          onChange={(e) => {
            if (e == "Other") {
              setPatient({ ...patient, gender: e });
              setothergender(true);
            } else {
              setothergender(false);
              setPatient({ ...patient, gender: e });
            }
          }}
        />
        {othergender || patient.gender == "Other" ? (
          <CustomInput
            label={"Other gender extra info"}
            required
            fullWidth
            isRow={!isMobile}
            value={patient.genderExtraInfo}
            onChange={(e) => {
              setPatient({ ...patient, genderExtraInfo: e });
            }}
          />
        ) : (
          <></>
        )}
        <div
          className={`d-flex w-100 gap-3 ${
            isMobile ? "flex-column" : "flex-row align-items-center "
          } `}
          style={{ marginLeft: "10px" }}
        >
          <label
            className="col-lg-3 country"
            style={
              isMobile
                ? { fontSize: "11px", fontWeight: "bold" }
                : { fontSize: "14px", fontWeight: "bold" }
            }
          >
            {/* <daiv>*</daiv> */}
            Date Of Birth
          </label>
          <DatePicker
            inputPlaceholder="Select a day"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            wrapperClassName="col-12 col-lg-8 "
            value={
              patient.birthday
                ? {
                    year: patient.birthday.getFullYear(),
                    month: patient.birthday.getMonth(),
                    day: patient.birthday.getDate(),
                  }
                : null
            }
            onChange={(e) => {
              setPatient({
                ...patient,
                birthday: new Date(e.year, e.month, e.day),
              });
            }}
          />
        </div>
        {step === 2 ? (
          <CustomInput
            label={"Relationship"}
            select
            options={options.EmergnecyContactRelationship}
            required
            fullWidth
            // hasError
            isRow={!isMobile}
            value={patient.relat}
            onChange={(e) => {
              setPatient({ ...patient, relationship: e });
            }}
          />
        ) : null}
        <CustomInput
          label={"Care Card(PHN)"}
          required
          fullWidth
          // hasError={true}
          column={12}
          isRow={!isMobile}
          value={insertBlankAfterEveryThreeCharacters(patient.careCard)}
          onChange={(e) => {
            setPatient({ ...patient, careCard: e.substr(0, 12) });
          }}
        />
        <CustomButton
          isLoading={isLoading}
          title={
            isLoading ? <PulseLoader color="white" /> : isEdit ? "Save" : "Next"
          }
          uiType="primary"
          additionalClassNames="col-3"
          onClick={async () => {
            setIsLoading(true);
            if (!patient.firstName) {
              Swal.fire({
                title: "",
                text: "First Name cannot be empty.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.lastName) {
              Swal.fire({
                title: "",
                text: "Last Name cannot be empty.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.birthday) {
              Swal.fire({
                title: "",
                text: "Date Of Birth cannot be empty.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.sex) {
              Swal.fire({
                title: "",
                text: "Sex cannot be empty.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.gender) {
              Swal.fire({
                title: "",
                text: "Gender Identity cannot be empty.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.careCard || patient.careCard.length !== 12) {
              Swal.fire({
                title: "",
                text: "Care Card must be 10 characters.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            }
            if (patient.id) {
              // console.log("meooooooooooooooo");
              var res = await putData("patient/" + patient.id, patient);
              // console.log(res);
              if (res.status === 200) {
                setIsLoading(false);
                // setStep(4);
                setisEdit(false);
                setIsOpen(false);
                setIsOpen2(true);
                fetchUser();
                Swal.fire({
                  title: "",
                  text: "Patient Updated Succesfully",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
              } else {
                Swal.fire({
                  title: "",
                  text: res,
                  icon: "error",
                  confirmButtonText: "Ok",
                });
                setIsLoading(false);
                return;
              }
            } else {
              var res = await postData("patient", patient);
              if (res.status === 201) {
                setPatient({ ...patient, id: res.data.data.id });
                setIsLoading(false);
                setStep(4);
              } else {
                Swal.fire({
                  title: "",
                  text: res,
                  icon: "error",
                  confirmButtonText: "Ok",
                });
                setIsLoading(false);
                return;
              }
            }
            // if (isEdit) {
            //   await editPatient();
            //   setisEdit(false);
            //   setIsOpen2(false);

            // setIsModalClose(false);
            // getPatients();
            //   return;
            // }
          }}
        />
      </div>
    </>
  );
};
const EmergnecyContact = (
  // Error,
  // setError,
  patient,
  options,
  setPatient,
  setStep,
  getFile,
  isMobile,
  loading,
  setLoading,
  setisEdit,
  isEdit = false,
  editPatient,
  setIsOpen,
  fetchUser,
  setIsOpen2,
  isFirst,
  setisfirst
) => {
  // const [Error, setError] = useState(true);

  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-1 px-2">
      <h2 style={{ marginBottom: "-20px" }}>
        Enter Patient’s Emergency Contact
      </h2>

      {patient.emergencyContacts.map((e, i) => {
        return (
          <>
            {
              <>
                <div className="d-flex flex-row align-items-start pt-3 w-100">
                  <div className="col-10 d-flex flex-column gap-3 align-items-center">
                    {isMobile &&
                      i !== 0 &&
                      (!loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={isMobile ? "16" : "30"}
                          height={isMobile ? "16" : "30"}
                          fill="red"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                          style={
                            isMobile
                              ? {
                                  cursor: "pointer",
                                  width: "35px",
                                }
                              : { cursor: "pointer" }
                          }
                          onClick={async () => {
                            if (e.id) {
                              setLoading(true);
                              var res = await deleteData(
                                `patient/${patient.id}/emergency-contact/${e.id}`
                              );
                              if (res.status == 200) {
                                setPatient({
                                  ...patient,
                                  emergencyContacts:
                                    patient.emergencyContacts.filter(
                                      (e, index) => {
                                        return index != i;
                                      }
                                    ),
                                });
                              }
                              setLoading(false);
                            } else {
                              setPatient({
                                ...patient,
                                emergencyContacts:
                                  patient.emergencyContacts.filter(
                                    (e, index) => {
                                      return index != i;
                                    }
                                  ),
                              });
                            }
                          }}
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                        </svg>
                      ) : (
                        <FadeLoader />
                      ))}
                    <div
                      style={
                        !isMobile
                          ? {
                              alignItems: "center",
                              marginLeft: "20px",
                              marginRight: "15px",
                            }
                          : { marginLeft: "20px", marginRight: "15px" }
                      }
                      className={
                        !isMobile
                          ? "d-flex flex-row w-100 gap-2"
                          : "d-flex flex-column w-100 gap-2"
                      }
                    >
                      <span
                        style={
                          isMobile
                            ? {
                                fontWeight: "bold",
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                                paddingLeft: "10px",
                              }
                            : {
                                fontWeight: "bold",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                              }
                        }
                        className="col-3 country"
                      >
                        Phone Number
                      </span>
                      <PhoneInput
                        className={
                          isMobile
                            ? "col-11 col-lg-8  mx-2 phon-input"
                            : "col-11 col-lg-8 my-1 mx-2 phon-input"
                        }
                        placeholder="Enter phone number"
                        defaultCountry="CA"
                        value={e.phoneNumber}
                        error={
                          e.phoneNumber
                            ? isValidPhoneNumber(e.phoneNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                        onChange={(v) => {
                          // console.log(v)
                          let updated = patient.emergencyContacts;
                          updated[i].phoneNumber = v;
                          setPatient({
                            ...patient,
                            emergencyContacts: updated,
                          });
                        }}
                      />
                      {!isMobile &&
                        i !== 0 &&
                        (!loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={isMobile ? "16" : "30"}
                            height={isMobile ? "16" : "30"}
                            fill="red"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                            style={
                              isMobile
                                ? {
                                    cursor: "pointer",
                                    width: "35px",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={async () => {
                              if (e.id) {
                                setLoading(true);
                                var res = await deleteData(
                                  `patient/${patient.id}/emergency-contact/${e.id}`
                                );
                                if (res.status == 200) {
                                  setPatient({
                                    ...patient,
                                    emergencyContacts:
                                      patient.emergencyContacts.filter(
                                        (e, index) => {
                                          return index != i;
                                        }
                                      ),
                                  });
                                }
                                setLoading(false);
                              } else {
                                setPatient({
                                  ...patient,
                                  emergencyContacts:
                                    patient.emergencyContacts.filter(
                                      (e, index) => {
                                        return index != i;
                                      }
                                    ),
                                });
                              }
                            }}
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        ) : (
                          <FadeLoader />
                        ))}
                    </div>
                    {patient.emergencyContacts[i].phoneNumber &&
                    isValidPhoneNumber(
                      patient.emergencyContacts[i].phoneNumber
                    ) ? (
                      <></>
                    ) : (
                      !isFirst && (
                        <div
                          style={
                            isMobile
                              ? {
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "-13px",
                                }
                              : {
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "-10px",
                                }
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            Phone number isn't valid
                          </div>
                        </div>
                      )
                    )}

                    <CustomInput
                      label={"Relationship"}
                      select
                      options={options.EmergnecyContactRelationship}
                      required
                      // hasError={!e.relationship}
                      isRow={!isMobile}
                      fullWidth
                      value={e.relationship}
                      onChange={(v) => {
                        let updated = patient.emergencyContacts;
                        updated[i].relationship = v;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />
                    {patient.emergencyContacts[i].relationship ? (
                      <></>
                    ) : (
                      !isFirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Please select relationship
                          </div>
                        </div>
                      )
                    )}
                    <CustomInput
                      label={"Name"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.fullName}
                      onChange={(v) => {
                        let updated = patient.emergencyContacts;
                        updated[i].fullName = v;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />
                    {patient.emergencyContacts[i].fullName ? (
                      <></>
                    ) : (
                      !isFirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            Please enter the Name
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="col-1" />
                </div>
                <div className="add-patient_line" />
              </>
            }
          </>
        );
      })}

      <div
        className="d-flex align-items-center w-100"
        style={{ cursor: "pointer", width: "55px" }}
        onClick={() => {
          setPatient({
            ...patient,
            emergencyContacts: [
              ...patient.emergencyContacts,
              {
                relationship: "",
                country: "",
                phoneNumber: "",
                itemOpen: true,
              },
            ],
          });
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
            fill="#18324E"
          />
        </svg>

        <div
          style={{
            fontSize: "14px",
            fontWeight: "700",
            color: "rgba(24, 50, 78, 1)",
            paddingLeft: "5px",
            paddingBottom: "2px",
          }}
        >
          Add More
        </div>
      </div>
      <CustomButton
        title={
          loading ? <PulseLoader color="white" /> : isEdit ? "Save" : "Next"
        }
        uiType="primary"
        onClick={async () => {
          setLoading(true);
          for (var i = 0; i < patient.emergencyContacts.length; ) {
            if (
              isValidPhoneNumber(patient.emergencyContacts[i].phoneNumber) &&
              patient.emergencyContacts[i].fullName &&
              patient.emergencyContacts[i].relationship
            ) {
              i++;
            } else {
              Swal.fire({
                title: "",
                text: "please input informtion",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setLoading(false);
              setisfirst(false);
              return;
            }
          }

          if (patient.emergencyContacts.length === 0) {
            setisfirst(true);
            setStep(5);
          } else {
            for (var i in patient.emergencyContacts) {
              if (patient.emergencyContacts[i].id) {
                console.log("id daram");
                var res2 = await putData(
                  `patient/${patient.id}/emergency-contact/${patient.emergencyContacts[i].id}`,
                  patient.emergencyContacts[i]
                );
                if (res2.status != 200) {
                  Swal.fire({
                    title: "",
                    text: res2,
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                }
              } else {
                var res2 = await postData(
                  `patient/${patient.id}/emergency-contact`,
                  patient.emergencyContacts[i]
                );
                if (res2.status == 201) {
                  patient.emergencyContacts[i].id = res2.data.data.id;
                }
                // console.log(res2)
                if (res2.status != 201) {
                  Swal.fire({
                    title: "",
                    text: res2,
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                }
              }
            }
            if (isEdit) {
              Swal.fire({
                title: "",
                text: "Patient Updated Successfully!",
                icon: "success",
                confirmButtonText: "Ok",
              });
              setisEdit(false);
              fetchUser();
              setIsOpen2(true);
              setIsOpen(false);
              setLoading(false);
              setisfirst(true);
              return;
            }
            setisfirst(true);
            setStep(5);
          }
          if (patient.referralBy == null) {
            setPatient({ ...patient, referralBy: {} });
          }
          setLoading(false);
        }}
      />
    </div>
  );
};

const EmailAddress = (
  patient,
  setPatient,
  setStep,
  getFile,
  isMobile,
  loading,
  setLoading,
  setisEdit,
  isEdit = false,
  editPatient,
  setIsOpen,
  fetchUser,
  setIsOpen2,
  isFirst,
  setisfirst
) => {
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-5 px-2">
      <h2 style={{ marginBottom: "-20px" }}>
        Enter Teacher/Support Provider Email Address
      </h2>

      {patient.teachers.map((e, i) => {
        return (
          <>
            {
              <>
                <div
                  style={{ maxWidth: "500px" }}
                  className="d-flex flex-row align-items-start pt-3 w-100"
                >
                  <div className="col-10 d-flex flex-column gap-3 align-items-center">
                    <div className="col-12 d-flex align-items-center">
                      <CustomInput
                        label={"First Name"}
                        required
                        isRow={!isMobile}
                        fullWidth
                        value={e.firstName}
                        onChange={(v) => {
                          let updated = patient.teachers;
                          updated[i].firstName = v;
                          setPatient({
                            ...patient,
                            teachers: updated,
                          });
                        }}
                      />
                      {i !== 0 &&
                        (!loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="red"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                            style={
                              isMobile
                                ? {
                                    cursor: "pointer",
                                    width: "30px",
                                    marginTop: "10px",
                                  }
                                : {
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: "15%",
                                  }
                            }
                            onClick={async () => {
                              if (e.id) {
                                setLoading(true);
                                var res = await deleteData(
                                  `patient/${patient.id}/teacher/${e.id}`
                                );
                                if (res.status == 200) {
                                  setPatient({
                                    ...patient,
                                    teachers: patient.teachers.filter(
                                      (e, index) => {
                                        return index != i;
                                      }
                                    ),
                                  });
                                }
                                setLoading(false);
                              } else
                                setPatient({
                                  ...patient,
                                  teachers: patient.teachers.filter(
                                    (e, index) => {
                                      return index != i;
                                    }
                                  ),
                                });
                            }}
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        ) : (
                          <FadeLoader
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: "15%",
                            }}
                          />
                        ))}
                    </div>
                    {patient.teachers[i].firstName ? (
                      <></>
                    ) : (
                      !isFirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Please enter First Name
                          </div>
                        </div>
                      )
                    )}
                    <CustomInput
                      label={"Last Name"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.lastName}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].lastName = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    {patient.teachers[i].lastName ? (
                      <></>
                    ) : (
                      !isFirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Please enter Last Name
                          </div>
                        </div>
                      )
                    )}
                    <CustomInput
                      label={"Email"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.email}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].email = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    {patient.teachers[i].email &&
                    EmailValidate(patient.teachers[i].email) ? (
                      <></>
                    ) : (
                      !isFirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Email address isn't valid
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="col-1" />
                </div>
                <div className="add-patient_line" />
              </>
            }
          </>
        );
      })}
      <div
        className="d-flex align-items-center w-100"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setPatient({
            ...patient,
            teachers: [
              ...patient.teachers,
              {
                firstName: "",
                lastName: "",
                email: "",
                itemOpen: true,
              },
            ],
          });
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
            fill="#18324E"
          />
        </svg>

        <div
          style={{
            fontSize: "14px",
            fontWeight: "700",
            color: "rgba(24, 50, 78, 1)",
            paddingLeft: "5px",
            paddingBottom: "2px",
          }}
        >
          Add More
        </div>
      </div>
      <div className="d-flex flex-row gap-2">
        {!isEdit && (
          <CustomButton
            title={"Skip"}
            uiType="primary2"
            onClick={async () => {
              setisfirst(true);
              setStep(7);
            }}
          />
        )}
        <CustomButton
          title={
            loading ? <PulseLoader color="white" /> : isEdit ? "Save" : "Next"
          }
          uiType="primary"
          onClick={async () => {
            setLoading(true);
            for (var i = 0; i < patient.teachers.length; ) {
              if (
                patient.teachers[i].firstName &&
                patient.teachers[i].lastName &&
                patient.teachers[i].email &&
                EmailValidate(patient.teachers[i].email)
              ) {
                i++;
              } else {
                Swal.fire({
                  title: "",
                  text: "please fill all information",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
                setisfirst(false);
                setLoading(false);
                return;
              }
            }
            if (patient.teachers.length < 1) {
              if (isEdit) {
                Swal.fire({
                  title: "",
                  text: "Patient Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
                setisfirst(true);
                setisEdit(false);
                fetchUser();
                setIsOpen(false);
                setIsOpen2(true);
                setLoading(false);

                return;
              }
              setisfirst(true);
              setStep(7);
            } else {
              for (var i in patient.teachers) {
                if (patient.teachers[i].id) {
                  var res2 = await putData(
                    `patient/${patient.id}/teacher/${patient.teachers[i].id}`,
                    patient.teachers[i]
                  );
                  if (res2.status != 200) {
                    Swal.fire({
                      title: "",
                      text: res2,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                  }
                } else {
                  var res2 = await postData(
                    `patient/${patient.id}/teacher`,
                    patient.teachers[i]
                  );
                  if (res2.status != 201) {
                    Swal.fire({
                      title: "",
                      text: res2,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                  }
                }
              }
              if (isEdit) {
                Swal.fire({
                  title: "",
                  text: "Patien updated successfuly!",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
                setIsOpen(false);
                setisEdit(false);
                setIsOpen2(true);
                fetchUser();
                setLoading(false);
                setisfirst(true);
                return;
              }
              setisfirst(true);
              setStep(7);
              setLoading(false);
            }
          }}
        />
      </div>
    </div>
  );
};

const Detile = (
  patient,
  isDetails,
  addPatient,
  editPatient,
  isLoading,
  setIsOpen,
  setIsOpen2,
  setStep,
  services,
  setPatient,
  fetchUser,
  isEdit,
  setisEdit
) => {
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  return (
    <div className="detile d-flex flex-column py-3 gap-5 w-100">
      <div>
        <table>
          <thead>
            <th colSpan={2}>
              Personal Information
              <svg
                onClick={() => {
                  setStep(2);
                  setisEdit(true);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>

          <tbody className="personalTable">
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                First Name: {" " + patient.firstName}
              </td>
              <td>Last Name: {" " + patient.lastName}</td>
            </tr>
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                Date of Birth:
                {patient.birthday
                  ? " " +
                    patient.birthday.getFullYear() +
                    "/" +
                    patient.birthday.getMonth() +
                    "/" +
                    patient.birthday.getDate()
                  : " "}
              </td>
              <td>
                Sex:{" " + patient.sex}{" "}
                {patient.sex == "Other" ? "/ " + patient.sexExtraInfo : ""}{" "}
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                Gender: {" " + patient.gender}
                {patient.gender == "Other"
                  ? "/ " + patient.genderExtraInfo
                  : ""}
              </td>
              <td>Relationship: {" " + patient.relationship} </td>
            </tr>
            <tr>
              <td style={{ borderRight: "1px solid rgba(242, 242, 242, 1)" }}>
                Care Card:{" "}
                {" " + insertBlankAfterEveryThreeCharacters(patient.careCard)}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table>
          <thead>
            <th colSpan={3}>
              Emergnecy Contact{" "}
              <svg
                onClick={() => {
                  setStep(4);
                  setIsOpen2(true);
                  setisEdit(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Phone Number</td>
              <td style={{ fontWeight: "bold" }}> Relationship</td>
              <td style={{ fontWeight: "bold" }}> Name</td>
            </tr>
            {patient.emergencyContacts.map((e) => {
              return (
                <tr>
                  <td>{e.phoneNumber}</td>
                  <td>{e.relationship}</td>
                  <td>{e.fullName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th colSpan={4}>
              Referral Information
              <svg
                onClick={() => {
                  setisEdit(true);
                  setStep(5);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>First Name</td>
              <td style={{ fontWeight: "bold" }}>Last Name</td>
              <td style={{ fontWeight: "bold" }}>Email</td>
              <td style={{ fontWeight: "bold" }}>Fax</td>
            </tr>

            {patient.referralBy && (
              <tr>
                <td>{patient.referralBy.firstName}</td>
                <td>{patient.referralBy.lastName}</td>
                <td>{patient.referralBy.email}</td>
                <td>{patient.referralBy.fax}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <table>
          <thead>
            <th colSpan={3}>
              Teacher/Care Provider Email Address{" "}
              <svg
                onClick={() => {
                  setisEdit(true);
                  setStep(6);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>First Name</td>
              <td style={{ fontWeight: "bold" }}>Last Name</td>
              <td style={{ fontWeight: "bold" }}>Email</td>
            </tr>
            {patient.teachers.map((t) => {
              return (
                <tr>
                  <td>{t.firstName}</td>
                  <td>{t.lastName}</td>
                  <td>{t.email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <table>
          <thead>
            <th colSpan={1}>
              Services
              <svg
                onClick={() => {
                  setisEdit(true);
                  setStep(7);
                  setIsOpen2(true);
                  setIsOpen(false);
                }}
                className="edit-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                    fill="#18324E"
                  />
                  <path
                    d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                    fill="#18324E"
                  />
                </g>
              </svg>
            </th>
          </thead>
          <tbody>
            {patient.serviceTypes.map((s) => {
              return (
                <tr>
                  <td>{s.serviceType.title}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomButton
        title={"Confirm"}
        additionalClassNames="col-3 mb-5"
        loading={isLoading}
        onClick={() => {
          if (!isDetails) {
            addPatient();
          } else {
            editPatient();
          }
        }}
      />
    </div>
  );
};

const Services = (
  services,
  setStep,
  setIsOpen,
  setIsOpen2,
  patient,
  setPatient,
  showService,
  setshowService,
  Checkitems,
  setCheckitems,
  setisEdit,
  isEdit = false,
  editPatient,
  // setIsOpen,
  fetchUser,
  isLoading,
  setIsLoading
) => {
  // const isMobile = useMobile();
  return (
    <div className="info d-flex flex-column w-100  px-3">
      <h2 className="mb-5">Choose at Least One Service</h2>

      {services.map((s, i) => {
        return s.children.length > 0 ? (
          <div className="services d-flex flex-column">
            <div style={{ display: "flex", alignItems: "center" }}>
              {!Checkitems[i] ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <mask id="path-1-inside-1_3497_120561" fill="white">
                    <rect width="16" height="16" rx="1" />
                  </mask>
                  <rect
                    width="16"
                    height="16"
                    rx="1"
                    stroke="#A1813A"
                    stroke-width="3"
                    mask="url(#path-1-inside-1_3497_120561)"
                  />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <mask id="path-1-inside-1_3497_120531" fill="white">
                    <rect width="16" height="16" rx="1" />
                  </mask>
                  <rect
                    width="16"
                    height="16"
                    rx="1"
                    stroke="#A1813A"
                    stroke-width="3"
                    mask="url(#path-1-inside-1_3497_120531)"
                  />
                  <rect
                    x="3.2002"
                    y="3.20001"
                    width="9.6"
                    height="9.6"
                    rx="1"
                    fill="#A1813A"
                  />
                </svg>
              )}
              <h5
                style={{ cursor: "pointer", fontWeight: "600" }}
                onClick={() => {
                  showService == s.title
                    ? setshowService("")
                    : setshowService(s.title);
                }}
              >
                {s.title}
              </h5>
            </div>
            <div
              // className="d-flex flex-column d-none"
              style={
                showService == s.title
                  ? {
                      display: "none",
                      display: "flex",
                      flexDirection: "column",
                    }
                  : {
                      display: "none",
                      display: "flex",
                      flexDirection: "column",
                      display: "none",
                    }
              }
            >
              {s.children.map((c) => {
                return (
                  <label className="radio" style={{ marginTop: "5px" }}>
                    <input
                      className="radio-input"
                      type="checkbox"
                      checked={
                        patient.serviceTypes.filter((s) => {
                          return s.serviceType.id == c.id;
                        }).length > 0
                      }
                      onChange={(e) => {
                        var index = patient.serviceTypes.filter((s) => {
                          return s.serviceType.id == c.id;
                        });

                        if (index.length == 0) {
                          setPatient({
                            ...patient,
                            serviceTypes: [
                              ...patient.serviceTypes,
                              { serviceType: c },
                            ],
                          });
                          let temp = Checkitems;
                          temp[i] = true;
                          setCheckitems(temp);
                          // console.log(Checkitems);
                        } else {
                          if (
                            patient.serviceTypes.filter((c) => {
                              // console.log(c.serviceType.parentId, s);
                              return c.serviceType.parentId == s.id;
                            }).length == 1
                          ) {
                            let temp = Checkitems;
                            temp[i] = false;
                            setCheckitems(temp);
                          }
                          setPatient({
                            ...patient,
                            serviceTypes: patient.serviceTypes.filter((s) => {
                              return s.serviceType.id != c.id;
                            }),
                          });
                        }
                      }}
                    />
                    <span className="custom-radio" />
                    {c.title}
                  </label>
                );
              })}
            </div>
            <hr />
          </div>
        ) : null;
      })}

      <div
        style={{ justifyContent: "center" }}
        className="d-flex flex-row w-100 my-5 py-3"
      >
        <CustomButton
          title={
            isLoading ? <PulseLoader color="white" /> : isEdit ? "Save" : "Next"
          }
          uiType="primary"
          additionalClassNames="col-3"
          onClick={async () => {
            setIsLoading(true);
            if (patient.serviceTypes.length < 1) {
              Swal.fire({
                title: "",
                text: "Choose at least one service",
                icon: "error",
                confirmButtonText: "Ok",
              });
              setIsLoading(false);
              return;
            } else {
              for (var i in patient.serviceTypes) {
                if (patient.serviceTypes[i].id) {
                  var res2 = await putData(
                    `patient/${patient.id}/service-type/${patient.serviceTypes[i].id}`,
                    {
                      serviceTypeId: patient.serviceTypes[i].serviceType.id,
                    }
                  );
                  if (res2.status != 200) {
                    Swal.fire({
                      title: "",
                      text: res2,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                  }
                } else {
                  var res2 = await postData(
                    `patient/${patient.id}/service-type`,
                    { serviceTypeId: patient.serviceTypes[i].serviceType.id }
                  );
                  if (res2.status != 201 && !isEdit) {
                    Swal.fire({
                      title: "",
                      text: res2,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                  }
                }
              }
            }
            if (isEdit) {
              if (patient.serviceTypes.length < 1) {
                Swal.fire({
                  title: "",
                  text: "Choose at Least One Service",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
                setIsLoading(false);
                return;
              }
              Swal.fire({
                title: "",
                text: "Patient Updated Successfully!",
                icon: "success",
                confirmButtonText: "Ok",
              });
              setisEdit(false);
              setIsOpen(false);
              setIsOpen2(true);
              fetchUser();
              setIsLoading(false);
              return;
            }
            setIsOpen(false);
            setIsOpen2(true);
            setStep(1);
            setshowService("");
            setIsLoading(false);
          }}
        />
      </div>
    </div>
  );
};

const Referral = (
  patient,
  setPatient,
  setStep,
  isMobile,
  isLoading,
  setIsLoading,
  setisEdit,
  isEdit = false,
  editPatient,
  setIsOpen,
  fetchUser
) => {
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 pb-5 pt-2 px-2">
      <h2 style={{ marginBottom: "-15px" }}>
        Enter Referring Doctor’s Information
      </h2>
      <div className="col-12 d-flex flex-column gap-3 align-items-center">
        <CustomInput
          disabled={patient.referralBy ? patient.referralBy.checked : false}
          label={"First Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.firstName : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.firstName = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <CustomInput
          disabled={patient.referralBy ? patient.referralBy.checked : false}
          label={"Last Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.lastName : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.lastName = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <CustomInput
          disabled={patient.referralBy ? patient.referralBy.checked : false}
          label={"Email"}
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.email : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.email = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <CustomInput
          disabled={patient.referralBy ? patient.referralBy.checked : false}
          label={"Fax"}
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.fax : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.fax = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <div>
          <input
            type="checkbox"
            onChange={(e) => {
              setPatient({
                ...patient,
                referralBy: {
                  ...patient.referralBy,
                  firstName: "",
                  lastName: "",
                  email: "",
                  fax: "",
                  checked: e.target.checked,
                },
              });
            }}
          />
          <label style={{ marginLeft: "7px" }}>
            I don’t have a referring doctor
          </label>
        </div>
      </div>

      <div className="d-flex flex-row gap-2">
        {!isEdit && (
          <CustomButton
            title={"Skip"}
            uiType="primary2"
            onClick={async () => {
              setStep(6);
            }}
          />
        )}
        <CustomButton
          title={
            isLoading ? <PulseLoader color="white" /> : isEdit ? "Save" : "Next"
          }
          uiType="primary"
          onClick={async () => {
            console.log(patient.referralBy);
            setIsLoading(true);
            if (
              (patient.referralBy && patient.referralBy.checked) ||
              patient.referralBy == null
            ) {
              if (patient.Referral && patient.referralBy.id) {
                var res = await deleteData(
                  "patient/" +
                    patient.id +
                    "/referral-by/" +
                    patient.referralBy.id
                );
                setPatient({ ...patient, referralBy: {} });
              }
              if (isEdit) {
                Swal.fire({
                  title: "",
                  text: "Patient Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
                setIsOpen(false);
                setisEdit(false);
                fetchUser();
                setIsLoading(false);
                return;
              }
              setStep(6);
            } else {
              // console.log("meooooooooo");
              if (patient.referralBy) {
                if (
                  patient.referralBy.firstName == "" ||
                  !patient.referralBy.firstName
                ) {
                  Swal.fire({
                    title: "",
                    text: "First Name cannot be empty.",
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                  setIsLoading(false);

                  return;
                }
                if (
                  patient.referralBy.lastName == "" ||
                  !patient.referralBy.lastName
                ) {
                  Swal.fire({
                    title: "",
                    text: "Last Name cannot be empty.",
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                  setIsLoading(false);

                  return;
                }
                if (patient.referralBy.email !== undefined) {
                  if (!EmailValidate(patient.referralBy.email)) {
                    Swal.fire({
                      title: "Invalid Email!",
                      text: "Please enter a valid Email",
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                    setIsLoading(false);

                    return;
                  }
                }

                if (patient.referralBy.fax !== undefined) {
                  if (!FaxValidate(patient.referralBy.fax)) {
                    Swal.fire({
                      title: "Invalid Fax Number!",
                      text: "Please enter a valid Fax Number",
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                    setIsLoading(false);

                    return;
                  }
                }
                // console.log("slkbcskjdbcvj")

                if (patient.referralBy.id) {
                  var res2 = await putData(
                    `patient/${patient.id}/referral-by/${patient.referralBy.id}`,
                    patient.referralBy
                  );
                  if (res2.status != 200) {
                    Swal.fire({
                      title: "",
                      text: res2,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                  }
                } else {
                  // console.log("test")
                  var res2 = await postData(
                    `patient/${patient.id}/referral-by`,
                    patient.referralBy
                  );

                  if (res2.status != 201 || res2.status == undefined) {
                    console.log(res2.state);
                    Swal.fire({
                      title: "",
                      text: res2,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                    setIsLoading(false);
                    return;
                  }
                }
              }

              if (isEdit) {
                Swal.fire({
                  title: "",
                  text: "Patient Updated Successfully!",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
                setIsOpen(false);
                setisEdit(false);
                fetchUser();
                setIsLoading(false);

                return;
              }
              setStep(6);
              // setIsLoading(false);
            }
            setIsLoading(false);
          }}
        />
      </div>
    </div>
  );
};

export default PatientList;
